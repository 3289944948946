<template>
	<section id="landing_header">
    <v-row no-gutters>
      <v-col cols="12">
        <v-img
         :aspect-ratio="1"
          :max-height="minHeight"
          :src="image_header"
          class="white--text blur-image"
        >
          <v-row justify="center" class="fill-height">
            <v-col cols="3" class="d-flex align-center">
              <v-btn icon color="landing_primary" @click="$router.go(-1)"><v-icon color="landing_primary" large>mdi-chevron-left</v-icon></v-btn>
            </v-col>
            <v-col cols="6" class="py-0 d-flex justify-center align-center">
              <v-card :height="logoSize" :width="logoSize" elevation="6" class="image__logo my-0">
                <v-row no-gutters justify="center" align="center" :style="{height:logoSize}">
                  <v-col cols="10">
                    <v-img
                      :width="logoSize"
                      :height="logoSize"
                      :aspect-ratio="1"
                      contain
                      :src="logo"
                      class="logo"
                    ></v-img>                    
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
            <v-col cols="3" class="d-flex justify-end">
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn depressed :small="$vuetify.breakpoint.smAndDown" color="white" class="mr-4 mt-5" v-on="on" style="padding-left: 10px !important; padding-right: 10px !important">
                    <div style="font-size:20px">
                      <flag :iso="language == 'en' ? 'us' : language == 'zh' ? 'cn' : language" />  
                    </div>
                    <v-icon small>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                    <v-list-item @click="changeLang('es')">
                      <v-list-item-title>
                        <div style="font-size:20px">
                          <flag iso="es" />                          
                        </div>
                      </v-list-item-title>
                    </v-list-item>    
                    <v-list-item @click="changeLang('en')">
                      <v-list-item-title>
                        <div style="font-size:20px">
                          <flag iso="us" />                          
                        </div>
                      </v-list-item-title>
                    </v-list-item>     
                    <v-list-item @click="changeLang('fr')">
                      <v-list-item-title>
                        <div style="font-size:20px">
                          <flag iso="fr" />                          
                        </div>
                      </v-list-item-title>
                    </v-list-item>     
                    <v-list-item @click="changeLang('zh')">
                      <v-list-item-title>
                        <div style="font-size:20px">
                          <flag iso="cn" />                          
                        </div>
                      </v-list-item-title>
                    </v-list-item>                   
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </v-img>
        <base-divider color="landing_primary" max-width="100%" space="0" />
      </v-col>
    </v-row>        
  </section>  
</template>
<script>
  import { mapGetters } from 'vuex'
  export default{
    name: 'HeaderInfo',
    methods:{
      changeLang(lang){
        this.$store.commit('SET_LANGUAGE', lang);
        this.$i18n.locale = lang;
      }
    },
    computed: {
      ...mapGetters({
        language: 'language',
        logo: 'landing/logo',
        image_header: 'landing/header',
      }),
      minHeight () {
        const height = this.$vuetify.breakpoint.mdAndUp ? '30vh' : '20vh'

        return `calc(${height} - ${this.$vuetify.application.top}px)`
      },
      logoSize () {
        const size = this.$vuetify.breakpoint.mdAndUp ? '8' : '6'

        return `${size}rem`
      },
    },
  }
</script>
<style lang="sass">
  .image__logo
    z-index: 4
    position: absolute

  .blur-image .v-image__image
    filter: blur(2px)

  .logo .v-image__image
     filter: saturate(100%) 

</style>